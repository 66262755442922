@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=PT+Serif:ital,wght@0,400;1,700&family=Roboto:wght@100&display=swap");
body {
  margin: 0;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (max-width: 353px) {
  .loginLogImage {
    width: 250px;
    height: 85px;
  }
}
a {
  text-decoration: none;
}
.active {
  background: linear-gradient(90.45deg, #faa43f 0.32%, #ff6813 101.55%);
  color: white;
}
a:hover {
  background: linear-gradient(90.45deg, #faa43f 0.32%, #ff6813 101.55%);
  color: white !important;
  transition: background 500ms ease-in;
}

a:hover div p {
  color: white !important;
}
a:hover div svg {
  color: white !important;
}
.active div svg {
  color: white !important;
}
.active div p {
  color: white !important;
}
.truncated-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
